import {useQueryClient} from 'react-query'
import {URL} from 'utils/urls'
import useMutateUser from './use_mutate_user'

export default function useAllMutations() {
  const {deleteUser, updateUser, signoutUser, revokeAuthorization} = useMutateUser()
  const queryClient = useQueryClient()

  const mutations = {
    deleteUser: deleteUser,
    updateUser: updateUser,
    signoutUser: signoutUser,
    revokeAuthorization: revokeAuthorization,

    invalidateQueries: (queries = []) => queryClient.invalidateQueries(queries),
    invalidatePlanQuery: () => queryClient.invalidateQueries([URL.route.current_plan])
  }

  return {mutations}
}
