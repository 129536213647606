import {BrowserExtensionContextProvider, Error500, FocusContextProvider, ModalContextProvider, RouteContextProvider, SearchContextProvider, SnackBarContextProvider, UserPlanContextProvider} from '@brikit/tabjay-ui-kit'
import MainLayout from 'components/layout/main_layout'
import NoIndexStaging from 'components/seo/no_index_staging'
import {initializeUiKitServerFunctions} from 'lib/ui_kit_server/server'
import {useState} from 'react'
import {ErrorBoundary} from "react-error-boundary"
import {Hydrate, QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
import {isDev} from 'utils/env_helpers'
import {tabjayNodeApi} from 'utils/tabjay_api_utils'
import GoogleTagManager from './seo/google_tag_manager'

export default function LoadableApp({Component, pageProps, session}) {

  initializeUiKitServerFunctions()

  const [queryClient] = useState(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: isDev ? false : true,
          retry: 1,
          queryFn: (queryKey) => {tabjayNodeApi.get(queryKey[0])},
          // Time to cache a query before the background refresh is needed again
          staleTime: 1000 * 60 * 5, // 5 minutes
          // time to cache a query before loading is shown again
          // cacheTime: 1000 * 60 * 5 // 5 minutes
        },
      },
    })
  })

  const AppWrapper = () => {
    return (
      <MainLayout>
        <ErrorBoundary fallback={<Error500 />}>
          <Component {...pageProps} />
        </ErrorBoundary>
      </MainLayout>
    )
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps?.dehydratedState}>
        <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'} />
        <RouteContextProvider>
          <SnackBarContextProvider>
            <UserPlanContextProvider>
              <SearchContextProvider>
                <FocusContextProvider>
                  <ModalContextProvider>
                    <BrowserExtensionContextProvider>
                      <NoIndexStaging />
                      <AppWrapper />
                      <GoogleTagManager />
                    </BrowserExtensionContextProvider>
                  </ModalContextProvider>
                </FocusContextProvider>
              </SearchContextProvider>
            </UserPlanContextProvider>
          </SnackBarContextProvider>
        </RouteContextProvider>
      </Hydrate>
    </QueryClientProvider>
  )
}
