import useMutationDefault from 'hooks/mutations/use_mutation_default'
import {useMutation} from 'react-query'
import {signOutHelper} from 'utils/helper_functions'
import {tabjayNodeApi} from 'utils/tabjay_api_utils'
import {URL} from 'utils/urls'

export default function useMutateUser() {
  const {defaultMutation} = useMutationDefault()
  const queriesToInvalidate = [URL.route.current_users, URL.route.current_plan]

  const updateUser = useMutation(
    (data) => tabjayNodeApi.patch(`${URL.route.users}/${data.id}`, data),
    defaultMutation(queriesToInvalidate)
  )

  const revokeAuthorization = useMutation(
    async (data) => await tabjayNodeApi.patch(`${URL.route.revoke_authorization}/${data.authorization.id}`, data.authorization),
    defaultMutation(queriesToInvalidate, {reload: true})
  )

  const signoutUser = useMutation(
    async (data) => await tabjayNodeApi.post(URL.route.logout, {userId: data.userId}),
    defaultMutation(queriesToInvalidate, {reload: true, callBack: () => signOutHelper()})
  )

  const deleteUser = useMutation(
    async (data) => await tabjayNodeApi.delete(`${URL.route.users}/${data.id}`),
    defaultMutation(queriesToInvalidate, {callBack: () => signOutHelper(URL.route.plan_deleted)})
  )

  return {updateUser, revokeAuthorization, signoutUser, deleteUser}
}