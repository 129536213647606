import {tabjayNodeApi} from 'utils/tabjay_api_utils'
import {URL} from 'utils/urls'

const queries = {
  currentUser: (userId) => userId && tabjayNodeApi.get(`${URL.route.current_user}`),
  plan: (userId, planId) => userId && planId && tabjayNodeApi.get(`${URL.route.current_plan}?id=${planId}`),
  availablePlans: (userIds) => tabjayNodeApi.get(`${URL.route.available_plans}`),
  invite: (code) => code && tabjayNodeApi.get(`${URL.route.invite}?code=${code}`),
  signedInUsers: () => tabjayNodeApi.get(URL.route.current_users),
}

export default queries