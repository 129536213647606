import {Mutations, useListenToDarkMode} from '@brikit/tabjay-ui-kit'
import {MainSeo} from 'components/seo/main_seo'
import SplashPage from 'components/splash'
import useAllMutations from 'hooks/mutations/use_all_mutations'

const MainLayout = ({children}) => {
  useListenToDarkMode()
  const {mutations} = useAllMutations()
  Mutations.mutations = mutations

  // DEV TEAM - can we merge MainSeo into parameters on TabjayLayout?
  return (
    <SplashPage>
      {/* <TabjayLayout className='main-view'> */}
      <MainSeo />
      {children}
      {/* </TabjayLayout> */}
    </SplashPage>
  )
}

export default MainLayout