import {tabjayNodeApi} from 'utils/tabjay_api_utils'
import {URL} from 'utils/urls'

const api = {
  createLinks: ({links}) => tabjayNodeApi.post(URL.route.links, links.map(l => l.values())),
  createPlan: ({plan, userPlan}) => tabjayNodeApi.post(`${URL.route.plans}`, {...plan.values(), ...userPlan.values(), id: plan.id, userPlanId: userPlan.id}),
  createTask: ({task, userTask}) => tabjayNodeApi.post(URL.route.tasks, {...task.values(), ...userTask.values(), priority: task.values().priority, userTaskId: userTask.id}),
  createTaskInvite: ({invite}) => tabjayNodeApi.post(URL.route.invite, invite.values()),
  createUserLink: ({userLink}) => tabjayNodeApi.post(URL.route.users_links, userLink.values()),

  updateTask: ({task}) => tabjayNodeApi.patch(`${URL.route.tasks}/${task.id}`, {...task.values(), priority: task.values().priority?.value}),
  updateLink: ({link}) => tabjayNodeApi.patch(`${URL.route.links}/${link.id}`, link.values()),
  updatePlan: ({plan}) => tabjayNodeApi.patch(`${URL.route.plans}/${plan.id}`, plan.values()),
  updateUser: ({user}) => tabjayNodeApi.patch(`${URL.route.users}/${user.id}`, user.values()), //TODO: Need to send currentPlanId
  updateUserLink: ({linkUser}) => tabjayNodeApi.patch(`${URL.route.users_links}/${linkUser.id}`, linkUser.values()),
  updateUserPlan: ({planUser}) => tabjayNodeApi.patch(`${URL.route.users_plans}/${planUser.id}`, planUser.values()), //TODO: check
  updateUserTask: ({taskUser}) => tabjayNodeApi.patch(`${URL.route.users_tasks}/${taskUser.id}`, taskUser.values()),

  deleteUser: ({user}) => tabjayNodeApi.delete(`${URL.route.users}/${user.id}`),
  deleteUserPlan: ({planUser}) => tabjayNodeApi.delete(`${URL.route.users_plans}/${planUser.id}`), //TODO: convert
  deleteTask: ({task}) => tabjayNodeApi.delete(`${URL.route.tasks}/${task.id}`, {data: task.values()}),
  leaveTask: ({taskUser}) => tabjayNodeApi.delete(`${URL.route.tasks}/${taskUser.id}`, {data: {...taskUser.values(), leave: true}}),
}

export default api
